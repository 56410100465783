import { IBlogCard, IInformationSection } from "@interface/propsInterface";
import {
  color,
  fontSize,
  fontWeight,
  screenBreakPoint,
} from "@styles/styleVariables";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pushItemUp } from "@styles/animations";

export const BlogSection = ({
  name,
  briefDescription,
  strapiId,
  coverImage,
  publishedAt,
  author,
  url,
}: IBlogCard) => {
  return (
    <SBlogCard variants={pushItemUp} whileInView="after" initial="before">
      <div className="blog-card-image-wrapper">
        <GatsbyImage
          image={coverImage ?? ""}
          alt={name ?? ""}
          className="blog-card-image"
        ></GatsbyImage>
        <div className="blog-card-image-overlay"></div>
      </div>
      <div className="blog-card-content">
        <div className="blog-card-title">
          <Link to={url} className="blog-card-title">{name ?? ""}</Link>
        </div>
        <div className="blog-card-content-misc">
          <p>{author}</p>
          <p>{new Date(publishedAt).toLocaleDateString()}</p>
        </div>
      </div>
    </SBlogCard>
  );
};

const SBlogCard = styled(motion.div)`
  margin: 1rem;
  width: 30%;
  height: 50rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${color.oxfordBlue};

  @media (max-width: ${screenBreakPoint.sm}) {
    width: 100%;
    height: 36rem;
  }

  .blog-card-image-wrapper {
    width: 100%;
    height: 66%;
    .blog-card-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: scaleX(-1);
    }
  }

  .blog-card-content {
    padding: 1rem;
    height: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .blog-card-title {
      font-weight: ${fontWeight.medium};
      font-size: ${fontSize.md};
      color: ${color.culture};
      text-decoration: underline;

      &:hover {
        transition: all 0.2s ease-in-out;
        color: ${color.sunglow};
      }
    }

    .blog-card-content-misc {
      font-weight: ${fontWeight.light};
      color: ${color.culture};
      font-size: ${fontSize.sm};
    }
  }
`;
