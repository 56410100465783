import { screenBreakPoint } from "@styles/styleVariables";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { BlogSection } from "./blogSection";
import { motion } from "framer-motion";
import { parentAnimation } from "@styles/animations";

export const BlogContent = () => {
  const query = useStaticQuery(graphql`
    query blogInformationQuery {
      blogInformationQuery: allStrapiBlogInformation(
        sort: { fields: publishedAt, order: DESC }
      ) {
        nodes {
          briefDescription
          strapi_id
          name
          useTemplate
          rank
          publishedAt
          author
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
          sublink
        }
      }
    }
  `);

  const blogInformationQuery = query.blogInformationQuery.nodes;

  return (
    <SBlogContent
      initial="before"
      whileInView="after"
      variants={parentAnimation(0.1)}
    >
      {blogInformationQuery?.map((mp: any) => (
        <BlogSection
          name={mp?.name ?? ""}
          briefDescription={mp?.briefDescription ?? ""}
          strapiId={mp?.strapi_id ?? ""}
          coverImage={
            mp?.coverImage?.localFile?.childImageSharp?.gatsbyImageData ?? ""
          }
          url={`/blog/${mp?.sublink ?? ""}`}
          buttonName={mp?.sublink?.name ?? ""}
          useTemplate={mp?.useTemplate ?? false}
          rank={mp?.rank ?? "9999"}
          publishedAt={mp?.publishedAt ?? "01/01/2001"}
          author={mp?.author ?? ""}
        ></BlogSection>
      ))}
    </SBlogContent>
  );
};

const SBlogContent = styled(motion.div)`
  min-height: 50vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem 0rem;

  @media (max-width: ${screenBreakPoint.sm}) {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
